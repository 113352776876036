import NotFound from "@components/static/NotFound/NotFound"
import { queries, request } from "@data/datocms"
import { loadOptionsData } from "@data/options"
import Layout from "@components/structure/Layout/layout"
import Seo from "@components/structure/Seo/Seo"

const NotFoundPage = ({ options, seo }) => {
  return (
    <Layout options={options}>
      {/* <Head>{renderMetaTags(metaTags)}</Head> */}
      {/* <SEO title="404: Not found" /> */}
      <Seo global={seo} title="404: Not found" />
      <NotFound />
    </Layout>
  )
}

export async function getStaticProps() {
  const options = await loadOptionsData()
  const { seo } = await request({ query: `{ ${queries.seo} }` })
  return {
    props: {
      options,
      seo,
    },
  }
}

export default NotFoundPage
