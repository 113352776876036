import Link from "next/link"
import React from "react"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import Bowtie from "./Bowtie.svg"
import * as styles from "./NotFound.module.scss"

const pageNotFound = () => (
  <div className={styles.NotFound}>
    <div className={styles.height}>
      <SiteWrapper className={styles.inner}>
        <h1>4O4</h1>
        <img src={Bowtie} alt="logo" />
        <h3>Oops! The page you were looking for doesn’t exist</h3>
        <Link href="/">
          <a>Return to homepage</a>
        </Link>
      </SiteWrapper>
    </div>
  </div>
)

export default pageNotFound
